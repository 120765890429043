import { RootState } from '../types';
import { InlenerPeriodicalsParams, MainState, PeriodicalsParams } from './types';
import { ActionTree, ActionContext } from 'vuex';
import { AxiosError } from 'axios';
import { adapter } from '../adapter';
import { Cao } from '@/interfaces/cao';
import { NewsItem } from '@/interfaces/NewsItem';
import { IInlenerSalaryTable, ISalaryTable } from '@/interfaces/salaryTable';

export const actions: ActionTree<MainState, RootState> = {

  caoRequest(store: ActionContext<MainState, RootState>,
             payload: { email: string, cao_name: string }): Promise<{ id: string, cao_name: string, email: string }> {
      return adapter.postRequest('/caoRequest/', payload);
  },

  getKennisBankCaos(store: ActionContext<MainState, RootState>): Promise<any> {
    return adapter.getRequest('/kennisbank-caos/', {}, true);
  },

  getKennisBank(store: ActionContext<MainState, RootState>): Promise<any> {
    return adapter.getRequest('/kennisbank-general/', {}, true);
  },

  getPdf(store: ActionContext<MainState, RootState>,
         payload: { html: string, filename: string }): Promise<HTMLAnchorElement> {
    return new Promise((resolve, reject) => {
      adapter.postRequest('/getuitzbev/', { html: payload.html }, true, { responseType: 'arraybuffer' })
        .then((responseData: any) => {
          const url = window.URL.createObjectURL(new Blob([responseData]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', payload.filename + '.pdf');
          document.body.appendChild(link);
          resolve(link);
        }).catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  getCaoPdf(store: ActionContext<MainState, RootState>,
            payload: { html: string, filename: string }): Promise<HTMLAnchorElement> {
    return new Promise((resolve, reject) => {
      adapter.postRequest('/get-pdf/', { html: payload.html }, true, { responseType: 'arraybuffer' })
        .then((responseData: any) => {
          const url = window.URL.createObjectURL(new Blob([responseData]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', payload.filename + '.pdf');
          document.body.appendChild(link);
          resolve(link);
        }).catch((error: AxiosError) => {
          reject(error);
        });
    });
  },

  getInlenersCaoPdf(store: ActionContext<MainState, RootState>,
                    payload: { html: string, filename: string }): Promise<HTMLAnchorElement> {
    return new Promise((resolve, reject) => {
      adapter.postRequest('/inlener/get-pdf/', { html: payload.html }, true, { responseType: 'arraybuffer' })
        .then((responseData: any) => {
          const url = window.URL.createObjectURL(new Blob([responseData]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', payload.filename + '.pdf');
          document.body.appendChild(link);
          resolve(link);
        }).catch((error: AxiosError) => {
          reject(error);
        });
    });
  },



  contactApi(store: ActionContext<MainState, RootState>, payload: {
    name: string,
    lastName: string,
    companyName: string,
    email: string,
    phone: string,
    supplier: string,
  }): Promise<{}> {
    return adapter.postRequest('/contact-api/', payload, false);
  },

  contactSupplier(store: ActionContext<MainState, RootState>, payload: {
    name: string,
    lastName: string,
    companyName: string,
    email: string,
    preference: string,
    reason: string,
  }): Promise<{}> {
    return adapter.postRequest('/contact-supplier/', payload, false);
  },

  getCaoTypes(store: ActionContext<MainState, RootState>, params: { [key: string]: any }): Promise<[]> {
    return adapter.getRequest('/cao-types/', params, false);
  },

  getCaoNames(store: ActionContext<MainState, RootState>): Promise<Array<{
    cao_id: string, readable_name_year: string }>> {
    return adapter.getRequest('/cao-names/', {}, false);
  },

  getToolCaoNames(store: ActionContext<MainState, RootState>): Promise<Array<{
    cao_id: string, readable_name_year: string, year_end_bonus_type: string, year_end_bonus_amount: string }>> {
    return adapter.getRequest('/tool-cao-names/', {}, store.rootGetters['account/isLoggedIn']);
  },

  getInlenerCaoNames(store: ActionContext<MainState, RootState>): Promise<Array<{
    id: string, readable_name: string, year_end_bonus_type: string, year_end_bonus_amount: string }>> {
    return adapter.getRequest('/inlener/cao-names/', {}, true);
  },

  getTrialCaos(store: ActionContext<MainState, RootState>): Promise<number[]> {
    return adapter.getRequest('/trial-caos/', {}, false);
  },

  getCaos(store: ActionContext<MainState, RootState>): Promise<Array<{ value: Cao, text: string }>> {
    return new Promise((resolve, reject) => {
      if (store.state.caos !== null) {
        resolve(store.state.caos);
      } else {
        adapter.getRequest('/caos/', {}, store.rootGetters['account/isLoggedIn'])
        .then((responseData: Cao[]) => {
          const result = responseData.sort((a, b) => a.readable_name_year > b.readable_name_year ? 1 : -1)
            .map((cao: Cao) => {
              return { value: cao, text: cao.readable_name_year };
            });
          store.commit('SET_CAOS', result);
          resolve(result);
        });
      }
    });
  },

  getSalaryTables(store: ActionContext<MainState, RootState>, params: { [key: string]: string }):
  Promise<ISalaryTable[]> {
    return adapter.getRequest(
      '/salary-tables/', { caoId: params.caoId, calcDate: params.fromDate }, store.rootGetters['account/isLoggedIn']);
  },

  getInlenerSalaryTables(store: ActionContext<MainState, RootState>, params: { [key: string]: string }):
  Promise<IInlenerSalaryTable[]> {
    return adapter.getRequest(
      '/inlener/salary-tables/',
      { inlenerCaoId: params.inlenerCaoId, calcDate: params.fromDate, current: params.current },
      store.rootGetters['account/isLoggedIn'],
    );
  },

  getInlenerPeriodicals(
    store: ActionContext<MainState, RootState>,
    payload: InlenerPeriodicalsParams,
    ): Promise<any> {
    return adapter.getRequest('/inlener/periodicals/', payload, store.rootGetters['account/isLoggedIn']);
  },

  getPeriodicals(
    store: ActionContext<MainState, RootState>,
    payload: PeriodicalsParams,
    ): Promise<any> {
    return adapter.getRequest('/periodicals/', payload, store.rootGetters['account/isLoggedIn']);
  },

  submitCaoTool(store: ActionContext<MainState, RootState>, params: { [key: string]: string }): Promise<any> {
    return adapter.getRequest('/salaries/', params, store.rootGetters['account/isLoggedIn']);
  },

  submitInlenerCaoTool(store: ActionContext<MainState, RootState>, params: { [key: string]: string }): Promise<any> {
    return adapter.getRequest('/inlener/salaries/', params, store.rootGetters['account/isLoggedIn']);
  },

  loadNews(store: ActionContext<MainState, RootState>): Promise<any> {
    return adapter.getRequest('/news/', {}, false).then((news: NewsItem[]) => {
      store.commit('SET_NEWS', news);
    });
  },

  loadVerhogingen(store: ActionContext<MainState, RootState>): Promise<any> {
    return adapter.getRequest('/verhogingen/', {}, false).then((verhogingen: string[][]) => {
      store.commit('SET_VERHOGINGEN',
        verhogingen.sort((a, b) => {
          return (+new Date(a[0]) - (+new Date(a[0])));
        }));
    });
  },
};
